<template>
  <div class="content-wrapper">
    <PageHeader screenName="Editar Pessoa Física" :linkItems="linkItems" />

    <main class="card" v-if="loading">
      <section class="form p-2">{{ mensagem }}</section>
    </main>
    <main class="card" v-else>
      <section class="form p-2">
        <validation-observer ref="pessoaFisicaRules">
          <b-form>
            <b-row>
              <b-col lg="4" md="6" sm="12">
                <b-form-group label="CPF*">
                  <validation-provider
                    #default="{ errors }"
                    name="cpf"
                    rules="required"
                  >
                    <vue-cleave
                      id="cpf-input"
                      v-model="pessoaFisica.cpf"
                      :raw="false"
                      :options="option.cpf"
                      class="form-control"
                      placeholder="000.000.000-00"
                      name="cpf"
                      maxlength="14"
                      autocomplete="off"
                      disabled
                    />
                  </validation-provider>
                </b-form-group>
              </b-col>

              <b-col lg="4" md="6" sm="12">
                <b-form-group label="Nome Completo*">
                  <validation-provider
                    #default="{ errors }"
                    name="nome"
                    rules="required"
                  >
                    <b-form-input
                      :state="
                        errors.length > 0 || errorApi.nome || errorApi.error
                          ? false
                          : null
                      "
                      id="nome-input"
                      v-model="pessoaFisica.nome"
                      placeholder="John Doe"
                    />
                    <small v-if="errors[0]" class="text-danger">
                      Este campo é de preenchimento obrigatório.
                    </small>
                    <small v-if="errorApi.nome" class="text-danger">
                      {{ errorApi.nome }}
                    </small>
                    <small v-if="errorApi.error" class="text-danger">
                      {{ errorApi.error }}
                    </small>
                  </validation-provider>
                </b-form-group>
              </b-col>

              <b-col lg="4" md="6" sm="12">
                <b-form-group label="Data de Nascimento*">
                  <validation-provider
                    #default="{ errors }"
                    name="data_nascimento"
                    rules="required"
                  >
                    <b-input-group>
                      <vue-cleave
                        id="data-nascimento-input"
                        v-model="pessoaFisica.data_nascimento"
                        :raw="false"
                        :options="option.data"
                        class="form-control"
                        placeholder="00/00/0000"
                        name="data_nascimento"
                        autocomplete="off"
                      />
                    </b-input-group>

                    <small v-if="errors[0]" class="text-danger">
                      Este campo é de preenchimento obrigatório.
                    </small>
                    <small v-if="errorApi.data_nascimento" class="text-danger">
                      {{ errorApi.data_nascimento[0] }}
                    </small>
                    <small v-if="errorApi.error" class="text-danger">
                      {{ errorApi.error }}
                    </small>
                  </validation-provider>
                </b-form-group>
              </b-col>

              <b-col lg="4" md="6" sm="12">
                <b-form-group label="Nome da Mãe">
                  <validation-provider #default="{ errors }" name="nome_mae">
                    <b-form-input
                      :state="
                        errors.length > 0 || errorApi.nome_mae ? false : null
                      "
                      id="nome_mae-input"
                      v-model="pessoaFisica.nome_mae"
                      placeholder="Nome da mãe"
                    />
                    <small v-if="errorApi.nome_mae" class="text-danger">
                      {{ errorApi.nome_mae }}
                    </small>
                    <small v-if="errorApi.error" class="text-danger">
                      {{ errorApi.errors }}
                    </small>
                  </validation-provider>
                </b-form-group>
              </b-col>

              <b-col lg="4" md="6" sm="12" class="mb-1">
                <NacionalidadeField
                  :key="renderNacionalidade"
                  :idNacionalidade="pessoaFisica.id_nacionalidade"
                  @updateNacionalidade="updateNacionalidade"
                  :errorApiIdNacionalidade="errorApi.id_nacionalidade"
                  customClass="register-colaborador"
                />
              </b-col>

              <b-col lg="4" md="6" sm="12">
                <validation-provider
                  #default="{ errors }"
                  name="genero"
                  rules="required"
                >
                  <b-form-group
                    label="Sexo*"
                    :state="errors.length > 0 || errorApi.genero ? false : null"
                  >
                    <v-select
                      id="genero"
                      v-model="pessoaFisica.genero"
                      :options="generoSelect"
                      label="topico"
                      placeholder="Selecione uma opção"
                      :clearable="true"
                    />
                    <small v-if="errors[0]" class="text-danger">
                      Este campo é de preenchimento obrigatório.
                    </small>
                    <small v-if="errorApi.genero" class="text-danger">
                      {{ errorApi.genero }}
                    </small>
                  </b-form-group>
                </validation-provider>
              </b-col>
            </b-row>
            <b-row>
              <section class="buttons p-2">
                <b-button
                  id="save-colaborador"
                  class="mr-2 mb-2 mb-sm-0"
                  variant="custom-blue"
                  @click.prevent="validationForm()"
                >
                  <feather-icon icon="CheckIcon" class="mr-50" />
                  <span class="align-middle">Atualizar</span>
                </b-button>
                <b-button
                  id="cancel-register-colaborador"
                  variant="outline-danger"
                  @click.prevent="cancelar"
                >
                  <feather-icon icon="XIcon" class="mr-50" />
                  <span class="align-middle">Cancelar</span>
                </b-button>
              </section>
            </b-row>
          </b-form>
        </validation-observer>
      </section>
    </main>
  </div>
</template>

<script>
import {
  BRow,
  BCol,
  BForm,
  BFormGroup,
  BFormInput,
  BInputGroup,
  BButton,
  BTable,
  BModal,
} from "bootstrap-vue";
import PageHeader from "@/views/components/custom/page-header/PageHeader.vue";
import CustomPagination from "@/views/components/custom/pagination/CustomPagination.vue";
import ButtonIcon from "@/views/components/custom/Buttons/ButtonIcon/ButtonIcon";
import VueCleave from "vue-cleave-component";
import moment from "moment";
import vSelect from "vue-select";
import { required } from "@validations";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import NacionalidadeField from "@/views/components/custom/field-select-nacionalidade/NacionalidadeField.vue";

export default {
  title: "Editar Pessoa Física",

  components: {
    BRow,
    BCol,
    BForm,
    BFormGroup,
    BFormInput,
    BInputGroup,
    BButton,
    BTable,
    BModal,
    PageHeader,
    CustomPagination,
    ButtonIcon,
    vSelect,
    ValidationProvider,
    ValidationObserver,
    VueCleave,
    NacionalidadeField,
  },
  data() {
    return {
      linkItems: [
        {
          name: "Suporte",
          routeName: "",
        },
        {
          name: "Ver Pessoa Física",
          routeName: "pessoa-fisica-list",
        },
        {
          name: "Editar Pessoa Física",
          active: true,
        },
      ],
      loading: true,
      pessoaFisica: {},
      mensagem: "Carregando os dados...",
      renderNacionalidade: 0,
      generoSelect: ["Masculino", "Feminino"],
      option: {
        data: {
          date: true,
          delimiter: "/",
          datePattern: ["d", "m", "Y"],
        },
        cpf: {
          numericOnly: true,
          uppercase: true,
          delimiters: [".", ".", "-"],
          blocks: [3, 3, 3, 2],
        },
      },
      errorApi: {
        data_nascimento: "",
        error: "",
        nome: "",
        erro: "",
        nome_ma: "",
        nome_mae: "",
        genero: "",
        id_nacionalidade: "",
      },
    };
  },
  beforeMount() {
    if (this.$route.params.idPessoaFisica == undefined) {
      this.cancelar();
    }

    this.$http
      .get(this.$api.getPessoaFisicaPorCpf(this.$route.params.cpf))
      .then(({ data }) => {
        data.data_nascimento = moment(data.data_nascimento).format(
          "DD/MM/YYYY"
        );
        data.id_nacionalidade =
          data.nacionalidade != null
            ? data.nacionalidade.id_nacionalidade
            : null;

        this.pessoaFisica = data;
        this.loading = false;
      })
      .catch(({ response: { data } }) => {
        this.mensagem = "Pessoa não encontrada.";
      });
  },
  methods: {
    validationForm() {
      this.$refs.pessoaFisicaRules.validate().then((success) => {
        if (success) {
          this.atualizaPessoaFisica();
        }
      });
    },
    atualizaPessoaFisica() {
      const rota =
        this.$api.suportePessoaFisica() +
        "/" +
        this.pessoaFisica.id_pessoa_fisica;

      const params = {
        id_pessoa_fisica: this.pessoaFisica.id_pessoa_fisica,
        cpf: this.pessoaFisica.cpf,
        data_nascimento: moment(
          this.pessoaFisica.data_nascimento,
          "DD/MM/YYYY"
        ).format("YYYY-MM-DD"),
        nome: this.pessoaFisica.nome,
        nome_mae: this.pessoaFisica.nome_mae,
        genero: this.pessoaFisica.genero,
        id_nacionalidade: this.pessoaFisica.id_nacionalidade,
      };

      this.$http
        .put(rota, params)
        .then(({ data }) => {
          this.$swal({
            title: "Sucesso.",
            icon: "success",
            iconColor: "green",
            html: `<div>Pessoa atualizada com sucesso.</div>`,
            confirmButtonText: "Ok",
            customClass: {
              confirmButton: "btn btn-custom-blue",
            },
            buttonsStyling: false,
          }).then((result) => {
            if (result.isConfirmed) {
              this.cancelar();
            }
          });
        })
        .catch(({ response: { data } }) => {
          this.errorApi = data.errors;
        });
    },
    updateNacionalidade(id_nacionalidade) {
      this.pessoaFisica.id_nacionalidade = id_nacionalidade;
    },
    cancelar() {
      this.$router.push({
        name: "pessoa-fisica-list",
      });
    },
  },
};
</script>