var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"content-wrapper"},[_c('PageHeader',{attrs:{"screenName":"Editar Pessoa Física","linkItems":_vm.linkItems}}),(_vm.loading)?_c('main',{staticClass:"card"},[_c('section',{staticClass:"form p-2"},[_vm._v(_vm._s(_vm.mensagem))])]):_c('main',{staticClass:"card"},[_c('section',{staticClass:"form p-2"},[_c('validation-observer',{ref:"pessoaFisicaRules"},[_c('b-form',[_c('b-row',[_c('b-col',{attrs:{"lg":"4","md":"6","sm":"12"}},[_c('b-form-group',{attrs:{"label":"CPF*"}},[_c('validation-provider',{attrs:{"name":"cpf","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('vue-cleave',{staticClass:"form-control",attrs:{"id":"cpf-input","raw":false,"options":_vm.option.cpf,"placeholder":"000.000.000-00","name":"cpf","maxlength":"14","autocomplete":"off","disabled":""},model:{value:(_vm.pessoaFisica.cpf),callback:function ($$v) {_vm.$set(_vm.pessoaFisica, "cpf", $$v)},expression:"pessoaFisica.cpf"}})]}}])})],1)],1),_c('b-col',{attrs:{"lg":"4","md":"6","sm":"12"}},[_c('b-form-group',{attrs:{"label":"Nome Completo*"}},[_c('validation-provider',{attrs:{"name":"nome","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"state":errors.length > 0 || _vm.errorApi.nome || _vm.errorApi.error
                        ? false
                        : null,"id":"nome-input","placeholder":"John Doe"},model:{value:(_vm.pessoaFisica.nome),callback:function ($$v) {_vm.$set(_vm.pessoaFisica, "nome", $$v)},expression:"pessoaFisica.nome"}}),(errors[0])?_c('small',{staticClass:"text-danger"},[_vm._v(" Este campo é de preenchimento obrigatório. ")]):_vm._e(),(_vm.errorApi.nome)?_c('small',{staticClass:"text-danger"},[_vm._v(" "+_vm._s(_vm.errorApi.nome)+" ")]):_vm._e(),(_vm.errorApi.error)?_c('small',{staticClass:"text-danger"},[_vm._v(" "+_vm._s(_vm.errorApi.error)+" ")]):_vm._e()]}}])})],1)],1),_c('b-col',{attrs:{"lg":"4","md":"6","sm":"12"}},[_c('b-form-group',{attrs:{"label":"Data de Nascimento*"}},[_c('validation-provider',{attrs:{"name":"data_nascimento","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                        var errors = ref.errors;
return [_c('b-input-group',[_c('vue-cleave',{staticClass:"form-control",attrs:{"id":"data-nascimento-input","raw":false,"options":_vm.option.data,"placeholder":"00/00/0000","name":"data_nascimento","autocomplete":"off"},model:{value:(_vm.pessoaFisica.data_nascimento),callback:function ($$v) {_vm.$set(_vm.pessoaFisica, "data_nascimento", $$v)},expression:"pessoaFisica.data_nascimento"}})],1),(errors[0])?_c('small',{staticClass:"text-danger"},[_vm._v(" Este campo é de preenchimento obrigatório. ")]):_vm._e(),(_vm.errorApi.data_nascimento)?_c('small',{staticClass:"text-danger"},[_vm._v(" "+_vm._s(_vm.errorApi.data_nascimento[0])+" ")]):_vm._e(),(_vm.errorApi.error)?_c('small',{staticClass:"text-danger"},[_vm._v(" "+_vm._s(_vm.errorApi.error)+" ")]):_vm._e()]}}])})],1)],1),_c('b-col',{attrs:{"lg":"4","md":"6","sm":"12"}},[_c('b-form-group',{attrs:{"label":"Nome da Mãe"}},[_c('validation-provider',{attrs:{"name":"nome_mae"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                        var errors = ref.errors;
return [_c('b-form-input',{attrs:{"state":errors.length > 0 || _vm.errorApi.nome_mae ? false : null,"id":"nome_mae-input","placeholder":"Nome da mãe"},model:{value:(_vm.pessoaFisica.nome_mae),callback:function ($$v) {_vm.$set(_vm.pessoaFisica, "nome_mae", $$v)},expression:"pessoaFisica.nome_mae"}}),(_vm.errorApi.nome_mae)?_c('small',{staticClass:"text-danger"},[_vm._v(" "+_vm._s(_vm.errorApi.nome_mae)+" ")]):_vm._e(),(_vm.errorApi.error)?_c('small',{staticClass:"text-danger"},[_vm._v(" "+_vm._s(_vm.errorApi.errors)+" ")]):_vm._e()]}}])})],1)],1),_c('b-col',{staticClass:"mb-1",attrs:{"lg":"4","md":"6","sm":"12"}},[_c('NacionalidadeField',{key:_vm.renderNacionalidade,attrs:{"idNacionalidade":_vm.pessoaFisica.id_nacionalidade,"errorApiIdNacionalidade":_vm.errorApi.id_nacionalidade,"customClass":"register-colaborador"},on:{"updateNacionalidade":_vm.updateNacionalidade}})],1),_c('b-col',{attrs:{"lg":"4","md":"6","sm":"12"}},[_c('validation-provider',{attrs:{"name":"genero","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                        var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Sexo*","state":errors.length > 0 || _vm.errorApi.genero ? false : null}},[_c('v-select',{attrs:{"id":"genero","options":_vm.generoSelect,"label":"topico","placeholder":"Selecione uma opção","clearable":true},model:{value:(_vm.pessoaFisica.genero),callback:function ($$v) {_vm.$set(_vm.pessoaFisica, "genero", $$v)},expression:"pessoaFisica.genero"}}),(errors[0])?_c('small',{staticClass:"text-danger"},[_vm._v(" Este campo é de preenchimento obrigatório. ")]):_vm._e(),(_vm.errorApi.genero)?_c('small',{staticClass:"text-danger"},[_vm._v(" "+_vm._s(_vm.errorApi.genero)+" ")]):_vm._e()],1)]}}])})],1)],1),_c('b-row',[_c('section',{staticClass:"buttons p-2"},[_c('b-button',{staticClass:"mr-2 mb-2 mb-sm-0",attrs:{"id":"save-colaborador","variant":"custom-blue"},on:{"click":function($event){$event.preventDefault();return _vm.validationForm()}}},[_c('feather-icon',{staticClass:"mr-50",attrs:{"icon":"CheckIcon"}}),_c('span',{staticClass:"align-middle"},[_vm._v("Atualizar")])],1),_c('b-button',{attrs:{"id":"cancel-register-colaborador","variant":"outline-danger"},on:{"click":function($event){$event.preventDefault();return _vm.cancelar.apply(null, arguments)}}},[_c('feather-icon',{staticClass:"mr-50",attrs:{"icon":"XIcon"}}),_c('span',{staticClass:"align-middle"},[_vm._v("Cancelar")])],1)],1)])],1)],1)],1)])],1)}
var staticRenderFns = []

export { render, staticRenderFns }